import React, { useEffect, useRef, useState } from 'react';
import style from './transformmindpodstable.module.css';
import { Button, Form, InputGroup, Modal, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Pagination from '../pagination/Pagination';
import 'react-h5-audio-player/lib/styles.css';
import { CiPause1, CiPlay1 } from 'react-icons/ci';
import ReactStars from 'react-stars';
import PaginationDropDown from '../paginationdropdown/PaginationDropDown';
import { FiEdit } from 'react-icons/fi';
import { HiOutlineEye } from 'react-icons/hi';
import { RiDeleteBinLine } from 'react-icons/ri';
import deletemodalimage from '../../assets/delete.png'
import { deleteOnePod, getOnePod, getPod, searchPod, updatePod } from '../../services/TransFormPodsService';
import Loading from '../loading/Loading';
import ReactSelect from 'react-select';

const TransformMindPodsTable = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [showView, setShowView] = useState(false);
    const [number, setNumber] = useState(10);
    const [filter, setfilter] = useState(true);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [totalItems, setTotalItems] = useState(0);
    const [deleteId, setDeleteId] = useState('');
    const [viewData, setViewData] = useState('');
    const user = JSON.parse(localStorage.getItem('userdata'));
    const [playingIndex, setPlayingIndex] = useState(null);
    const playerRefs = useRef([]);
    const navigate = useNavigate();

    const params = {
        created_by: user?.id,
        skip: currentPage * number,
        limit: number,
        name: searchQuery,
        isAdmin: true,
        selfHypnotic: filter
    };

    useEffect(() => {
        getPodData();
    }, [currentPage, number, searchQuery, filter]);

    const getPodData = async () => {
        try {
            setLoading(true);
            if (searchQuery) {
                const response = await searchPod(params);
                setData(response?.data);
                setTotalItems(response?.total);
            } else {
                const response = await getPod(params);
                setData(response?.data);
                setTotalItems(response?.total);
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    const handleOnDelete = async () => {
        try {
            await deleteOnePod({ id: deleteId });
            setShowModal(false);
            getPodData();
            if (data?.length === 1 || undefined) {
                setCurrentPage(0)
            }
        } catch (error) {
            console.error("Error deleting pods:", error);
        }
    };

    const reformatDate = (dateStr) => {
        const dateObj = new Date(dateStr);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = months[dateObj.getUTCMonth()];
        const day = dateObj.getUTCDate();
        const year = dateObj.getUTCFullYear();
        return `${month} ${day}, ${year}`;
    };

    const playAudio = (index) => {
        if (playingIndex !== null && playingIndex !== index) {
            // Pause the currently playing audio
            playerRefs.current[playingIndex]?.pause();
        }
        if (playingIndex === index) {
            // Pause the same audio if it's already playing
            playerRefs.current[index]?.pause();
            setPlayingIndex(null);
        } else {
            // Play the selected audio
            playerRefs.current[index]?.play();
            setPlayingIndex(index);
        }
    };

    const handleAudioEnd = () => {
        setPlayingIndex(null);
    };

    const handleOnView = async (viewId) => {
        try {
            setLoading(true)
            const response = await getOnePod({
                id: viewId
            });
            setViewData(response?.data);
            setLoading(false)
            setShowView(true)

        } catch (error) {
            console.error(error);
        }
    }

    const handleOnUpdate = async (id, value) => {
        try {
            setLoading(true)
            await updatePod({
                isRecommended: value
            }, { id: id });
            getPodData()
            setLoading(false)
        } catch (error) {
            console.error("Error submitting pods:", error);
        }
    }

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            top: 'auto',
            left: 'auto',
            right: '0',
            bottom: 'auto',
            marginTop: '2px',
            zIndex: 9999,
        }),
        control: (provided, state) => ({
            ...provided,
            borderRadius: '80px',
            border: '1px solid #B8B8B8',
            '&:hover': {
                borderRadius: '80px',
                border: '1px solid #B8B8B8',
            },
        }),
        menuList: (provided, state) => ({
            ...provided,
            border: '1px solid #BDBDBD',
            borderRadius: '10px',
            backgroundColor: `#FFFFFF`,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isHovered ? '#769AA3' : '#FFFFFF',
            color: state.isHovered ? '#FFFFFF' : '#769AA3',
            padding: '5px',
            '&:hover': {
                backgroundColor: '#769AA3',
                color: '#FFFFFF',
            },
        }),
    };

    return (
        <>
            {loading && <Loading />}
            <div className='mt-sm-4 mt-2'>
                <div className='mt-sm-4 d-lg-flex mb-sm-3 mb-2 justify-content-between'>
                    <div className='d-sm-flex gap-3 align-items-center'>
                        <div className='d-flex align-items-center gap-2'>
                            <p className={style.content}>Per Page :</p>
                            <PaginationDropDown number={number} setNumber={setNumber} setCurrentPage={setCurrentPage} />
                            <button className={`btn d-sm-none ${style.button} ms-auto`} onClick={() => navigate('/transformyourmind-pods/add-pods')} >
                                <svg className='me-2' width="18" height="18" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="17" cy="17" r="16.5" stroke="white" strokeDasharray="4 4" />
                                    <path d="M10.5833 16.9997H23.4166M17 10.583V23.4163" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                Add New
                            </button>
                        </div>
                        <InputGroup className={`${style.searchbar} mt-sm-0 mt-1`}>
                            <InputGroup.Text id="inputGroup-sizing-default" className={style.searchButton}>
                                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22.875 21.1875L17.625 15.9375C18.8438 14.3438 19.5938 12.375 19.5938 10.2188C19.5938 5.0625 15.375 0.84375 10.2188 0.84375C5.0625 0.84375 0.75 5.0625 0.75 10.2188C0.75 15.375 4.96875 19.5938 10.125 19.5938C12.2812 19.5938 14.3438 18.8438 15.9375 17.5312L21.1875 22.7813C21.375 22.9688 21.75 23.1562 22.0312 23.1562C22.3125 23.1562 22.5938 23.0625 22.875 22.7813C23.3438 22.4063 23.3438 21.6562 22.875 21.1875ZM3.09375 10.2188C3.09375 6.375 6.28125 3.1875 10.125 3.1875C13.9688 3.1875 17.1562 6.375 17.1562 10.2188C17.1562 14.0625 13.9688 17.25 10.125 17.25C6.28125 17.25 3.09375 14.1563 3.09375 10.2188Z" fill="black" />
                                </svg>
                            </InputGroup.Text>
                            <Form.Control
                                type='text'
                                className={style.inputfieldsearch}
                                placeholder={'Search by name'}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </InputGroup>
                    </div>
                    <div className='d-flex align-items-center gap-3 mt-sm-0 mt-1`'>
                        <p className={style.content}>Selfhypnotic :</p>
                        <div className={style.container}>
                            <ReactSelect
                                aria-label="Items per page"
                                className={`${style.inputfieldselect}`}
                                styles={customStyles}
                                options={[
                                    { value: true, label: 'Yes' },
                                    { value: false, label: 'No' },
                                ]}
                                value={{ value: filter, label: filter === false ? 'No' : 'Yes' }}
                                onChange={(option) => { setfilter(option.value); setCurrentPage(0) }}
                            />
                        </div>
                        <button className={`btn d-sm-inline d-none ${style.button} mt-xl-0 mt-2 me-2`} onClick={() => navigate('/transformyourmind-pods/add-pods')} >
                            <svg className='me-2' width="18" height="18" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="17" cy="17" r="16.5" stroke="white" strokeDasharray="4 4" />
                                <path d="M10.5833 16.9997H23.4166M17 10.583V23.4163" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            Add New
                        </button>
                    </div>
                </div>
                <div id="table-container" className={`${style.tableContainer} mt-2`}>
                    <Table responsive borderless>
                        <thead>
                            <tr className={`${style.rowheading} ${style.section1}`}>
                                <th className='text-center'>Listen Audio</th>
                                <th>Pods Name</th>
                                <th>Pods Name</th>
                                <th>Expert Name</th>
                                <th>Category</th>
                                <th>Created On</th>
                                <th>Status</th>
                                <th className='text-center'>Rating</th>
                                <th className='text-center'>Actions</th>
                                <th className='text-center'>Recommendation</th>
                            </tr>
                        </thead>
                        <tbody className={style.tabledata}>
                            {
                                data?.length > 0 ? data.map((record, index) => (
                                    <tr key={index}>
                                        <td className='text-center'>
                                            {record?.audioFile ?
                                                <>
                                                    <button type='button' className={`btn ${style.play} p-0`} onClick={() => playAudio(index)}>
                                                        {playingIndex === index ? <CiPause1 size={24} /> : <CiPlay1 size={24} />}
                                                    </button>
                                                    <audio
                                                        ref={(el) => (playerRefs.current[index] = el)}
                                                        src={`${record?.audioFile}`}
                                                        onEnded={handleAudioEnd}
                                                    />
                                                </>
                                                : '-'}
                                        </td>
                                        <td>{record?.name}</td>
                                        <td>{record?.g_name}</td>
                                        <td>{record?.expertName ? record?.expertName : '-'}</td>
                                        <td>{record?.category ? record?.category : '-'}</td>
                                        <td>{reformatDate(record?.createdAt)}</td>
                                        <td>{record?.status === false ? <div className={style.inactive}>In Active</div> : <div className={style.active}>Active</div>}</td>
                                        <td >
                                            <ReactStars
                                                count={5}
                                                value={record?.rating}
                                                className='d-flex justify-content-center'
                                                size={24}
                                                edit={false}
                                                color2={'#FEBF1E'} />
                                        </td>
                                        <td>
                                            <div className='d-flex justify-content-center gap-3'>
                                                <FiEdit className={style.edit} onClick={() => navigate(`/transformyourmind-pods/edit-pods/${record?._id}`)} />
                                                <HiOutlineEye className={style.view} onClick={() => handleOnView(record?._id)} />
                                                <RiDeleteBinLine className={style.delete} onClick={() => { setShowModal(true); setDeleteId(record?._id) }} />
                                            </div>
                                        </td>
                                        <td className='d-flex justify-content-center gap-3'>
                                            <button type='button' style={{ width: '80px' }} className={`btn ${record?.isRecommended ? style.active : style.yes}`} onClick={() => handleOnUpdate(record?._id, true)}>Yes</button>
                                            <button type='button' style={{ width: '80px' }} className={`btn ${!record?.isRecommended ? style.inactive : style.no}`} onClick={() => handleOnUpdate(record?._id, false)}>No</button>
                                        </td>
                                    </tr>
                                ))
                                    :
                                    <tr className='text-center'>
                                        <td colSpan={8}><h4 className='p-4'>Data Not Found</h4></td>
                                    </tr>
                            }
                        </tbody>
                    </Table>
                    {totalItems > number ?
                        <Pagination
                            pageCount={Math.ceil(totalItems / number)}
                            currentPage={currentPage}
                            handlePageChange={handlePageChange}
                        />
                        : ''
                    }
                </div>
                <Modal size='sm' show={showModal} onHide={() => setShowModal(false)} centered backdrop='static' keyboard={false} contentClassName='gap-3'>
                    <Modal.Header className='border-0 p-0 justify-content-center'>
                        <img src={deletemodalimage} alt="Success" style={{ height: '100px' }} />
                    </Modal.Header>
                    <Modal.Body className='p-0'><h1 className={`${style.modelcontent} mb-0 mt-3 text-center`}>Are you sure you want to delete this pods?</h1></Modal.Body>
                    <Modal.Footer className='px-0 border-0 justify-content-center'>
                        <Button className={style.submitbutton} onClick={handleOnDelete}>
                            Delete
                        </Button>
                        <Button className={style.cacelbutton} onClick={() => { setShowModal(false) }}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showView} size='lg' onHide={() => setShowView(false)} centered backdrop='static' keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>TransformYourMind Pods</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='d-xl-flex gap-5 px-0 p-sm-4'>
                        <div className={style.modalsection}>
                            {
                                (viewData?.lang === 'english' || viewData?.lang === 'both') && (
                                    <>
                                        <p className={`${style.modalheading} mb-3`}>TransformYourMind Pod Name</p>
                                        <div className={`${style.modalcontent} mb-3`}>
                                            <p className='mb-0'>{viewData?.name}</p>
                                        </div>
                                        <p className={`${style.modalheading} my-3`}>Description</p>
                                        <div className={`${style.modallargecontent} mb-3`}>
                                            <p className='mb-0'>{viewData?.description}</p>
                                        </div>
                                    </>
                                )
                            }
                            <p className={`${style.modalheading} mb-3`}>Category</p>
                            <div className={`${style.modalcontent} mb-3`}>
                                <p className='mb-0'>{viewData?.category}</p>
                            </div>
                            <p className={`${style.modalheading} mb-3`}>TransformYourMind Pod Image</p>
                            <img src={`${viewData?.image}`} className={style.podimage} alt="Pods Image" />
                            <p className={`${style.modalheading} my-3`}>Alarms Audio</p>
                            <audio className='w-100' controls>
                                <source src={`${viewData?.audioFile}`} type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>
                            <p className={`${style.modalheading} mb-3`}>Status</p>
                            <div className={`${style.modalcontent} mb-3`}>
                                <p className='mb-0'>{viewData?.status === false ? 'In Active' : 'Active'}</p>
                            </div>
                            <p className={`${style.modalheading} mb-3`}>TransformYourMind Pods By</p>
                            <div className={`${style.modalcontent} mb-3`}>
                                <p className='mb-0'>{viewData?.pods_by === false ? 'TransformYourMind' : 'Expert'}</p>
                            </div>
                            {viewData?.pods_by === false ? '' :
                                <>
                                    <p className={`${style.modalheading} mb-3`}>Expert Name</p>
                                    <div className={`${style.modalcontent} mb-3`}>
                                        <p className='mb-0'>{viewData?.expertName}</p>
                                    </div>
                                </>
                            }
                        </div>
                        <div className={style.modalsection}>
                            {
                                (viewData?.lang === 'german' || viewData?.lang === 'both') && (
                                    <>
                                        <p className={`${style.modalheading} mb-3`}>Name des Transformyourmind-Pods</p>
                                        <div className={`${style.modalcontent} mb-3`}>
                                            <p className='mb-0'>{viewData?.g_name || '-'}</p>
                                        </div>
                                        <p className={`${style.modalheading} my-3`}>Beschreibung</p>
                                        <div className={`${style.modallargecontent} mb-3`}>
                                            <p className='mb-0'>{viewData?.g_description || '-'}</p>
                                        </div>
                                    </>
                                )}
                            {viewData?.isPaid === false ? '' :
                                <>
                                    <p className={`${style.modalheading} mb-3`}>SelfHypnotic </p>
                                    <div className={`${style.modalcontent} mb-3`}>
                                        <p className='mb-0'>{viewData?.selfHypnotic ? 'Yes' : 'No'}</p>
                                    </div>
                                </>
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        </>
    );
}

export default TransformMindPodsTable;